@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Bold.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-BoldItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-BoldItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Heavy.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Bold.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-HeavyItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Heavy.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Light.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Light.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-LightItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-HeavyItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Medium.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-MediumItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-MediumItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-RegularItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-RegularItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Regular.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Regular.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-LightItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Medium.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-SemiBold.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-SemiBold.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-SemiBoldItalic.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Regular.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert TRIAL';
  src: url('./fonts/roobert-trial/RoobertTRIAL-Regular.woff2') format('woff2'),
    url('./fonts/roobert-trial/RoobertTRIAL-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$primary-font-family: 'Roobert TRIAL';
$secondary-fony-family: 'IBM Plex Sans';

$font-size-default: 16px;
$font-weight-default: normal;
$font-style-default: normal;

$btn-font-size-default: 16px;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $secondary-fony-family;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 1.875em;
}
