@import './styles/base.scss';

body {
  background: #030015;
  color: $color-white;
}

.landing-wrap {
  padding-bottom: 100px;

  .header {
    height: 80px;
    position: relative;
    z-index: 999;
    padding: 0 105px 0;

    .container {
      height: 100%;
    }

    .wrap {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: stretch;

      .logo-area {
        width: 30%;

        a {
          display: inline-block;
          cursor: pointer;
        }

        img {
          height: 28px;
        }
      }

      .nav-links {
        width: 70%;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            display: inline-block;
            margin-right: 75px;

            &:last-child {
              margin: 0;
            }

            a {
              cursor: pointer;
              display: block;
              color: $color-white;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .hero-area {
    position: relative;
    height: 700px;
    margin-top: -40px;
    padding: 0 105px 0;

    .container {
      position: relative;
      height: 100%;
      z-index: 99;
    }

    .wrap {
      height: 100%;
      display: flex;
      align-items: center;
      justify-items: stretch;
    }

    .left {
      display: flex;
      align-items: center;
      width: 40%;
      height: 100%;

      .pre {
        color: $color-pink;
      }

      h1 {
        font-weight: 600;
        padding-top: 20px;

        span {
          color: $color-pink;
        }
      }

      .caption {
        margin: 15px 0 35px;
      }

      .app-stores {
        gap: 15px;
        display: flex;
        align-items: center;
        justify-items: stretch;

        a {
          cursor: pointer;

          img {
            height: 50px;
          }
        }
      }
    }

    .right {
      width: 60%;
      height: 100%;
      background-size: 100%;
      background-position: center;
      background-image: url(./assets/mobile-hero.png);
      background-repeat: no-repeat;
    }

    &::before {
      content: '';
      position: absolute;
      top: -50px;
      left: 0;
      width: 50%;
      bottom: 0;
      background: linear-gradient(141.55deg, #f72585 -3.79%, #ffbedc 82.82%);
      opacity: 0.1;
      filter: blur(100px);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -300px;
      right: 0;
      width: 1000px;
      height: 500px;
      background: linear-gradient(141.55deg, #575eff -3.79%, #e478ff 82.82%);
      opacity: 0.1;
      filter: blur(100px);
      transform: rotate(85.26deg);
    }
  }
  
  .caption-a {
    margin: 60px 0 0;

    h3 {
      display: block;
      text-align: center;
      max-width: 400px;
      margin: 0 auto;
      font-weight: 500;
      font-size: 28px;
    }

    .illus {
      height: 550px;
      background-image: url(./assets/mobile-flat.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .product {
    position: relative;
    padding: 0 105px 0;

    .contain {
      display: flex;
      align-items: flex-start;
      padding: 100px 35px 100px 0;

      h4 {
        font-size: 24px;
        font-weight: 500;
      }

      p {
        padding-top: 10px;
      }

      .coming-soon {
        display: inline-block;
        vertical-align: middle;
        background: rgba(240, 240, 240, 0.3);
        border-radius: 20px;
        font-weight: normal;
        font-size: 12px;
        padding: 8px 15px;
        margin-left: 5px;
        font-weight: 500;
      }
    }

    .row {
      border-bottom: 1px solid #e5e5e5;

      &:last-child {
        border-bottom: 0;
      }

      .contain {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 450px;
          border-right: 1px solid #e5e5e5;
        }

        img {
          height: 60px;
          margin-bottom: 35px;
        }
      }

      &:first-child .contain::after {
        height: 950px;
        top: -50px;
      }

      .colx:last-child .contain::after {
        content: unset;
      }
    }

    .illus-coins-box {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 300px;
      height: 300px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      background-image: url(./assets/coins-box.svg);
    }
  }

  .caption-b {
    padding: 110px 105px 0;
    .wrap {
      height: 400px;
      max-width: 100%;
      margin: 0 auto;
      background: linear-gradient(79.07deg, #e4d7ff 0.77%, #ffebf4 61.98%, #e4d7ff 103.98%);
      border-radius: 20px;

      .wrap-content {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
      }

      .left {
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;

        .inner {
          max-width: 350px;
        }

        h1 {
          color: $color-black;
        }

        p {
          color: $color-black;
        }

        .app-stores {
          gap: 15px;
          display: flex;
          align-items: center;
          justify-items: stretch;

          a {
            cursor: pointer;

            img {
              height: 50px;
            }
          }
        }
      }

      .left {
        align-self: baseline !important;
      }

      .illus {
        width: 60%;
        margin-top: 30px;
        height: calc(100% - 30px);
        background-repeat: no-repeat;
        background-position: center right;
        background-size: contain;
        background-image: url(./assets/illustration-trade.png);
      }
    }
  }

  .footer {
    margin-top: 105px;
    padding: 0 105px 0;

    .logo-area {
      cursor: pointer;
      text-decoration: none;

      img {
        height: 28px;
      }
    }

    .copyr {
      margin-top: 15px;
      font-size: 13px;
    }

    .social-links {
      list-style: none;
      margin: 0;
      padding: 15px 0 0;

      li {
        display: inline-block;
        vertical-align: top;
        margin-right: 15px;
        margin-bottom: 15px;

        &:last-child {
          margin: 0;
        }

        a {
          cursor: pointer;
        }
      }
    }

    .nav-links {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        color: $color-white;
        text-decoration: none;
        cursor: pointer;
      }

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      li.title {
        color: $color-white;
        opacity: 0.5;
      }
    }

    .newsletter-sync {
      p {
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-family: $secondary-fony-family;
        font-size: 18px;
        max-width: 280px;
      }

      form {
        gap: 15px;
        margin-top: 25px;
        border: 1px solid $color-white;
        background: rgba(255, 255, 255, 0.1);
        display: flex;
        align-items: center;
        justify-content: stretch;
        padding: 0 15px;

        input,
        button {
          border: 0;
          padding: 0;
          margin: 0;
          background: transparent;
        }

        input {
          flex: 1;
          height: 50px;
          padding: 0;
          color: $color-white !important;
          outline: none;
          font-size: 14px;
        }

        input::placeholder {
          color: $color-white;
        }

        button {
          font-size: 14px;
          color: $color-pink;
          padding: 0;
        }
      }
    }
  }

  @media (max-width: 991px) {

    .header {
      padding: 0 18px 0;
      .nav-links {
      display: none;
      }
    }

    .hero-area {
      height: auto;
      margin-top: 50px;
      padding: 0 18px 0;

      .wrap {
        display: block;
      }

      .left {
        width: 100%;
      }

      .right {
        height: 500px;
        width: 100%;
        background-size: 150%;
        background-position: top right;
      }

      &::before {
        top: -200px;
        width: 90%;
      }

      &::after {
        left: 10%;
        bottom: 0;
      }
    }

    .caption-a {
      padding-left: 18px;
      padding-right: 18px;

      h3 {
      max-width: 320px;
      }
    }

    .product{ 
      padding-left: 18px;
      padding-right: 18px;
      .row {
      border: 0;

      .contain {
        text-align: center;
        padding: 0;
        margin-bottom: 50px;

        &::after {
          border: 0;
        }

        .inner {
          max-width: 300px;
          margin: 0 auto;

          img {
            margin-right: auto;
            margin-left: auto;
          }
        }
      }

      &:last-child .colx-last .contain {
        margin-bottom: 0;
      }
    }
        }

    .illus-coins-box {
      display: none;
    }

    .caption-b {
      padding: 50px 0 0;

      .container {
        padding: 0;
      }

      .wrap {
        border-radius: 0;
        height: auto;

        .wrap-content {
          display: block;
          padding: 50px 0 0;

          .left {
            display: block;
            width: 100%;
            text-align: center;

            .inner {
              margin: 0 auto;
            }
          }

          .illus {
            width: 100%;
            height: 400px;
            background-position: bottom center;
          }

          h1 {
            font-size: 1.5em;
          }
        }
      }
    }

    .footer {
      margin-top: 50px;
      padding: 0 18px 0;

      .nav-links {
        margin-top: 50px;
      }

      .newsletter-sync {
        margin-top: 50px;
      }
    }
  }
}
