@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables';
@import './typography';
@import './include-media';
@import './colors';

@-ms-viewport {
  width: device-width;
}

html,
body {
  width: 100%;
  margin: 0;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  background: $color-white;
  font-size: $font-size-default;
  color: $color-text-default;
  font-weight: $font-weight-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  font-family: $primary-font-family;
  font-style: $font-style-default;
  overflow-x: hidden !important;

  iframe {
    display: none !important;
  }
}

#root {
  position: relative;
}

.h-100p {
  height: 100% !important;
}

.max-w-250x {
  max-width: 250px !important;
}
.max-w-350x {
  max-width: 350px !important;
}
.max-w-500x {
  max-width: 500px !important;
}
.max-w-400x {
  max-width: 400px !important;
}
.max-w-600x {
  max-width: 600px !important;
}
.max-w-700x {
  max-width: 700px !important;
}
.max-w-800x {
  max-width: 800px !important;
}

.margin-none {
  margin: 0 !important;
}
.margin-auto_center {
  margin: 0 auto !important;
}

.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.centered {
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.centered:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.centered .centered-content {
  vertical-align: middle;
  max-width: 98%;
  display: inline-block;
  text-align: left !important;
}

[data-vertical_center],
[data-vertical_center='true'] {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

[data-vertical_end],
[data-vertical_end='true'] {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.vgap--1x {
  height: 0.1rem;
}
.vgap--2x {
  height: 0.2rem;
}
.vgap--3x {
  height: 0.3rem;
}
.vgap--4x {
  height: 0.4rem;
}
.vgap--5x {
  height: 0.5rem;
}
.vgap-1x {
  height: 1rem;
}
.vgap-2x {
  height: 2rem;
}
.vgap-3x {
  height: 3rem;
}
.vgap-4x {
  height: 4rem;
}
.vgap-5x {
  height: 5rem;
}

.hgap--1x {
  display: inline-block;
  vertical-align: middle;
  width: 0.1rem;
}
.hgap--2x {
  display: inline-block;
  vertical-align: middle;
  width: 0.2rem;
}
.hgap--3x {
  display: inline-block;
  vertical-align: middle;
  width: 0.3rem;
}
.hgap--4x {
  display: inline-block;
  vertical-align: middle;
  width: 0.4rem;
}
.hgap--5x {
  display: inline-block;
  vertical-align: middle;
  width: 0.5rem;
}
.hgap-1x {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
}
.hgap-2x {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
}
.hgap-3x {
  display: inline-block;
  vertical-align: middle;
  width: 2rem;
}
.hgap-4x {
  display: inline-block;
  vertical-align: middle;
  width: 4rem;
}
.hgap-5x {
  display: inline-block;
  vertical-align: middle;
  width: 5rem;
}

.pt--1x {
  padding-top: 0.1rem;
}
.pt--2x {
  padding-top: 0.2rem;
}
.pt--3x {
  padding-top: 0.3rem;
}
.pt--4x {
  padding-top: 0.4rem;
}
.pt--5x {
  padding-top: 0.5rem;
}
.pt-1x {
  padding-top: 1rem;
}
.pt-2x {
  padding-top: 2rem;
}
.pt-3x {
  padding-top: 2rem;
}
.pt-4x {
  padding-top: 4rem;
}
.pt-5x {
  padding-top: 5rem;
}

.px-25x {
  padding-left: 25px;
  padding-right: 25px;
}

::-moz-placeholder {
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}
::-webkit-input-placeholder {
  font-size: $font-size-default;
  font-weight: $font-weight-default;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /*-webkit-box-shadow: 0 0 0 30px white inset !important;*/
  transition: background-color 5000s ease-in-out 0s;
}

//removes spinners from number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
